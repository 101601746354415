// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-about-page-tsx": () => import("./../../../src/templates/AboutPage.tsx" /* webpackChunkName: "component---src-templates-about-page-tsx" */),
  "component---src-templates-attend-page-tsx": () => import("./../../../src/templates/AttendPage.tsx" /* webpackChunkName: "component---src-templates-attend-page-tsx" */),
  "component---src-templates-cookie-policy-page-tsx": () => import("./../../../src/templates/CookiePolicyPage.tsx" /* webpackChunkName: "component---src-templates-cookie-policy-page-tsx" */),
  "component---src-templates-event-page-tsx": () => import("./../../../src/templates/EventPage.tsx" /* webpackChunkName: "component---src-templates-event-page-tsx" */),
  "component---src-templates-index-page-tsx": () => import("./../../../src/templates/IndexPage.tsx" /* webpackChunkName: "component---src-templates-index-page-tsx" */),
  "component---src-templates-partners-page-tsx": () => import("./../../../src/templates/PartnersPage.tsx" /* webpackChunkName: "component---src-templates-partners-page-tsx" */),
  "component---src-templates-privacy-policy-page-tsx": () => import("./../../../src/templates/PrivacyPolicyPage.tsx" /* webpackChunkName: "component---src-templates-privacy-policy-page-tsx" */),
  "component---src-templates-speakers-page-tsx": () => import("./../../../src/templates/SpeakersPage.tsx" /* webpackChunkName: "component---src-templates-speakers-page-tsx" */),
  "component---src-templates-talk-page-tsx": () => import("./../../../src/templates/TalkPage.tsx" /* webpackChunkName: "component---src-templates-talk-page-tsx" */),
  "component---src-templates-volunteer-page-tsx": () => import("./../../../src/templates/VolunteerPage.tsx" /* webpackChunkName: "component---src-templates-volunteer-page-tsx" */),
  "component---src-templates-watch-page-tsx": () => import("./../../../src/templates/WatchPage.tsx" /* webpackChunkName: "component---src-templates-watch-page-tsx" */)
}

